<template>
	<div class="estate-any">
		<sidebar />
		<div class="right">
			<header-top />
			<div class="page-title">
				<span>二次供水泵房管理 资产分析</span>
				<div class="title-right">
					<ul>
						<li>首页</li>
						<li>/</li>
						<li>统计分析</li>
						<li>/</li>
						<li>资产分析</li>
					</ul>
				</div>
			</div>
			<div class="my-2 count-wrap">
				<el-card class="box-card" shadow="never">
					<div class="card-inner">
						<div class="left">
							<span><i class="bi bi-building"></i></span>
							<span>小区</span>
						</div>
						<div class="count-num">
							<animate-number
								from="0"
								to="2021"
								duration="2000"
							></animate-number>
						</div>
					</div>
				</el-card>
				<el-card class="box-card" shadow="never">
					<div class="card-inner">
						<div class="left">
							<span><i class="bi bi-house-door"></i></span>
							<span>泵房</span>
						</div>
						<div class="count-num">
							<animate-number
								from="0"
								to="2021"
								duration="2000"
							></animate-number>
						</div>
					</div>
				</el-card>
				<el-card class="box-card" shadow="never">
					<div class="card-inner">
						<div class="left">
							<span><i class="bi bi-hdd-rack"></i></span>
							<span>水泵</span>
						</div>
						<div class="count-num">
							<animate-number
								from="0"
								to="2021"
								duration="2000"
							></animate-number>
						</div>
					</div>
				</el-card>
			</div>
			<div class="d-flex">
				<div class="chart-wrap">
					<div class="main" id="main1" style="width:750px;height:650px"></div>
				</div>
				<div class="chart-wrap">
					<div class="main" id="main2" style="width:750px;height:650px"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import * as echarts from 'echarts'
import sidebar from '../components/sidebar.vue'
import headerTop from '../components/header.vue'
export default {
	name: 'estate-any',
	components: {
		sidebar,
		headerTop,
	},
	data() {
		return {}
	},
	methods: {
		drawLine1() {
			// 基于准备好的dom，初始化echarts实例
			let myChart = echarts.init(document.getElementById('main1'))
			// 指定图表的配置项和数据
			let option = {
				title: {
					text: '某站点用户访问来源',
					subtext: '纯属虚构',
					left: 'center',
					textStyle: {
						color: '#fff',
					},
				},
				tooltip: {
					trigger: 'item',
				},
				legend: {
					orient: 'vertical',
					left: 'left',
					textStyle: {
						color: '#fff',
					},
				},
				series: [
					{
						name: '访问来源',
						type: 'pie',
						radius: '50%',
						data: [
							{ value: 1048, name: '搜索引擎' },
							{ value: 735, name: '直接访问' },
							{ value: 580, name: '邮件营销' },
							{ value: 484, name: '联盟广告' },
							{ value: 300, name: '视频广告' },
						],
						emphasis: {
							itemStyle: {
								shadowBlur: 10,
								shadowOffsetX: 0,
								shadowColor: 'rgba(0, 0, 0, 0.5)',
							},
						},
					},
				],
			}
			myChart.setOption(option)
		},
		drawLine2() {
			// 基于准备好的dom，初始化echarts实例
			let myChart = echarts.init(document.getElementById('main2'))
			// 指定图表的配置项和数据
			let option = {
				xAxis: {
					type: 'category',
					data: ['宝安区', '罗湖区', '南山区', '龙华区'],
					axisLabel: {
						color: '#4c9bfd',
					},
				},
				yAxis: {
					type: 'value',
					axisLabel: {
						color: '#4c9bfd',
					},
				},
				series: [
					{
						data: [
							{
								value: 120,
								itemStyle: {
									color: '#fa8c35',
								},
							},
							{
								value: 200,
								itemStyle: {
									color: '#c93756',
								},
							},
							{
								value: 160,
								itemStyle: {
									color: '#ff0097',
								},
							},
							{
								value: 150,
								itemStyle: {
									color: '#1bd1a5',
								},
							},
						],
						type: 'bar',
					},
				],
			}
			myChart.setOption(option)
		},
	},
	mounted() {
		this.drawLine1()
		this.drawLine2()
	},
}
</script>

<style lang="scss">
.estate-any {
	*:focus,
	*:active {
		outline: none !important;
	}
	li {
		list-style: none;
	}
	background: url(~@/assets/images/energyManage/background.jpg) 700px 700px;
	display: flex;
	.right {
		flex: 8;
		padding: 0 20px;
		.page-title {
			font-size: 15px;
			border-bottom: 1px solid rgba(255, 255, 255, 0.15);
			margin: 0;
			display: flex;
			color: #fff;
			justify-content: space-between;
			align-items: center;
			padding: 10px 0;
			.title-right {
				ul {
					display: flex;
					margin: 0;
					li {
						font-size: 13px;
						margin: 0 5px;
						cursor: pointer;
					}
					li:last-child {
						color: #ffd600;
					}
					li:nth-child(1):hover,
					li:nth-child(3):hover,
					li:nth-child(5):hover {
						color: #ffd600;
					}
				}
			}
		}
		.count-wrap {
			display: flex;
			flex-wrap: wrap;
			.box-card {
				flex: 0 0 auto;
				margin: 0 20px 20px 0;

				color: #fff;

				.el-card__body {
					padding: 10px;
				}

				&:nth-child(1) {
					background-color: #4b5cc4;
				}

				&:nth-child(2) {
					background-color: #8d4bbb;
				}

				&:nth-child(3) {
					background-color: #ff2d51;
				}

				.card-inner {
					display: flex;
					align-items: center;
					.left {
						flex: 0 0 auto;
						min-width: 120px;
						span {
							display: block;
							text-align: center;
							i {
								font-size: 3.1rem;
							}
						}
					}

					.count-num {
						font-size: 4rem;
						font-weight: 600;
					}
				}
			}
		}

		.chart-wrap {
			padding: 15px;
			background-color: rgba(0, 0, 0, 0.35);
			margin-right: 20px;
			margin-bottom: 20px;
		}
	}
}
</style>
